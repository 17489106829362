import { TeamOutlined, BookOutlined, FileTextOutlined, LineChartOutlined } from '@ant-design/icons';

export const menus = [
	{
		isParent: true,
		key: 'khach-hang',
		path: '',
		title: 'Khách hàng',
		icon: <TeamOutlined />,
		nested: [
			{
				key: `/khach-hang/thong-tin-chung.html`,
				path: `${process.env.REACT_APP_CUSTOMER_DOMAIN}/khach-hang/thong-tin-chung.html`,
				title: 'Tìm kiếm',
			},
		],
	},

	{
		isParent: true,
		key: 'booking',
		path: '',
		title: 'Booking',
		icon: <BookOutlined />,
		nested: [
			{
				key: '/admin/listing-booking-test.html',
				path: `${process.env.REACT_APP_CURRENT_ERP}/admin/listing-booking-test.html`,
				title: 'Danh sách',
			},
		],
	},
	{
		isParent: true,
		key: 'hoa-don',
		path: '',
		title: 'Hoá đơn',
		icon: <LineChartOutlined />,
		nested: [
			{
				key: '/',
				path: '/',
				title: 'Danh sách bill',
			},
			{
				key: '/danh-sach-hoa-don',
				path: '/danh-sach-hoa-don',
				title: 'Bill đã thanh toán',
			},
			{
				key: '/danh-sach/hang-doi-tra.html',
				path: `${process.env.REACT_APP_CURRENT_ERP}/danh-sach/hang-doi-tra.html`,
				title: 'Danh sách hàng trả lại',
			},
			{
				key: 'time-line',
				path: process.env.REACT_APP_CHECKIN_DOMAIN,
				title: 'Timeline',
			},
			{
				key: '/tra-hang',
				path: '/tra-hang',
				title: 'Trả hàng',
			},
		],
	},
	{
		isParent: true,
		key: 'bao-cao',
		path: '',
		title: 'Báo cáo',
		icon: <FileTextOutlined />,
		nested: [
			{
				key: '/admin/bao-cao/doanh-so-my-pham.html',
				path: `${process.env.REACT_APP_CURRENT_ERP}/admin/bao-cao/doanh-so-my-pham.html`,
				title: 'Mỹ phẩm',
			},
			{
				key: '/admin/bao-cao/doanh-so-dich-vu.html',
				path: `${process.env.REACT_APP_CURRENT_ERP}/admin/bao-cao/doanh-so-dich-vu.html`,
				title: 'Dịch vụ',
			},
		],
	},
];
